import React, { useEffect, useState } from 'react'
import $ from "jquery"
import apiurl from '../Global';
import Cookies from 'universal-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { FaGlobe,FaPlus } from 'react-icons/fa';
import Validateuser from './Validateuser';
import Loading from './includes/Loading';



const cookies = new Cookies();

export default function Managewebsites() {

    const [loading, setLoading] = useState(true)
    const [websites, setWebsite] = useState([])
    const navigate = useNavigate()
    var a = 0
    useEffect(() => {
        if(a == 0){
            $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/assets/css/stylesheet.css">')
            let getToken = cookies.get('token')
            if(typeof getToken !== "undefined" && getToken !== ""){
                fetch_website()
                // navigate("/manage-websites")
            }else{
            }
            a++;
        }
    }, [])

    
    const fetch_website = () => {
        let vendor = cookies.get('vendor')
        let getToken = cookies.get('token')

        fetch(apiurl+"api/website_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                token: vendor.token,
            })
            })
            .then(response => response.json())
            .then(response => {
                // alert(response.message)
                setWebsite(response.list)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
            });
    }


  return (
    <>
    <Validateuser />
    {
        !loading ?
        <>
            <Link to={"/add-website"} title="Add New Website">
                <div className='addwebsite'>
                    <FaPlus /> &nbsp;Add New Website
                </div>
            </Link>
            <div className='manageWebsites'>
                <div className='container managewebsiteBody'>

                    {
                        websites.length > 0 ?
                        websites.map((item, index) => (
                            
                            <a href={"https://"+item.subdomain+".kellysearch.co.in"} target="_BLANK">
                                <div className='websiteBox'>
                                    <p className='center-text websitename'>
                                        <FaGlobe /> &nbsp;&nbsp;{item.name}
                                    </p>
                                </div>
                            </a>
                        ))
                    :
                    <div>No Websites Found</div>
                    }
                </div>
            </div>
        </>
    :
        <Loading />
    }
    </>

  )
}
