import React, { useEffect, useState } from 'react'
import $ from "jquery"
import apiurl from '../Global';
import Cookies from 'universal-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { FaGlobe,FaPlus } from 'react-icons/fa';
import Validateuser from './Validateuser';

const cookies = new Cookies();



export default function Addwebsite() {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(true)
    const [domain, setDomain] = useState("")
    
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [slug, setSlug] = useState("")

    const navigate = useNavigate()
    // const{ user } = Validateuser()
    var a = 0
    
    useEffect(() => {
            if(a == 0){
                $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/assets/css/stylesheet.css">')
                setTimeout(function(){
                    setLoading(false)
                },500)
            a++;
        }
    }, [])

    const validateInput = (e) => {
        console.log("Asd")
        e.preventDefault()
        let vendor = cookies.get('vendor')
        let getToken = cookies.get('token')
        fetch(apiurl+"api/add_website", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                token: vendor.token,
                domain: domain,
                username: username,
                password: password,
                slug: slug,
            })
            })
            .then(response => response.json())
            .then(response => {
                alert(response.message)
            })
            .catch(err => {
                console.log(err);
            });
    }

  return (
    <>
    <Validateuser />
    {
        !loading &&
        <div className='manageWebsites'>
            <div className='container managewebsiteBody addwebsiteformbody'>
                <div className='formbody'>
                    <h2 className='center-text'>Add New Website</h2>
                    <p className='center-text pxpara'>
                        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate.
                    </p>
                    <div className='container'>
                        <form method="POST" onSubmit={validateInput}>
                            <div className="inputs container center-text">
                                <label>
                                    <strong>
                                        Website Name
                                    </strong>
                                    <br /><br />
                                    <input type="text" name="domain" className='center-text'  placeholder="Enter your Website Name" required="" onChange={e => setDomain(e.target.value)}/>
                                </label>
                            </div>
                            <div className="inputs container center-text">
                                <label>
                                    <strong>
                                        Username
                                    </strong>
                                    <br /><br />
                                    <input type="text" name="username" className='center-text'  placeholder="Enter your Username" required="" onChange={e => setUsername(e.target.value)}/>
                                </label>
                            </div>
                            <div className="inputs container center-text">
                                <label>
                                    <strong>
                                        Password
                                    </strong>
                                    <br /><br />
                                    <input type="password" name="password" className='center-text'  placeholder="Enter your Password" required="" onChange={e => setPassword(e.target.value)}/>
                                </label>
                            </div>
                            <div className="inputs container center-text">
                                <label>
                                    <strong>
                                        Slug
                                    </strong>
                                    <br /><br />
                                    <input type="text" name="slug" className='center-text'  placeholder="Enter your Admin Slug" required="" onChange={e => setSlug(e.target.value)}/>
                                </label>
                            </div>
                            <div className="inputs container center-text">
                                <label>
                                    <input type={"submit"} />
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )
}
