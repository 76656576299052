import React, { useState, useEffect } from 'react'
import $ from "jquery";
import Scripts from './Scripts'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const height = window.innerHeight;
const width = window.innerWidth;

export default function Sidebar(props) {
  
  const [isloadind, setIsloading] = useState(true)
  const navigate = useNavigate()
  var a = 0
  useEffect(() => {
    if(a == 0){
        
        let getToken = cookies.get('token')
        if(typeof getToken == "undefined" || getToken == ""){
            navigate("/Login")
        }else{
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/bootstrap/css/bootstrap.min.css">')
          $('head').append('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.min.css">')
          $('head').append('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/dist/css/AdminLTE.min.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/dist/css/skins/_all-skins.min.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/iCheck/flat/blue.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/morris/morris.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/jvectormap/jquery-jvectormap-1.2.2.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/datepicker/datepicker3.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/daterangepicker/daterangepicker.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/bootstrap-wysihtml5/bootstrap3-wysihtml5.min.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/select2/select2.min.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/dist/css/AdminLTE.min.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/dist/css/skins/_all-skins.min.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/assets/css/switcher.css">')
          $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/plugins/datatables/dataTables.bootstrap.css">')
          $('head').append('<link rel="stylesheet" href="https://cdn.datatables.net/buttons/2.0.1/css/buttons.dataTables.min.css">')
          $('body').addClass('hold-transition skin-blue sidebar-mini')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/assets/js/Main.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/jQuery/jquery-2.2.3.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="https://code.jquery.com/ui/1.11.4/jquery-ui.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/bootstrap/js/bootstrap.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="https://cdnjs.cloudflare.com/ajax/libs/raphael/2.1.0/raphael-min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/morris/morris.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/sparkline/jquery.sparkline.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/knob/jquery.knob.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.11.2/moment.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/daterangepicker/daterangepicker.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/datepicker/bootstrap-datepicker.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/bootstrap-wysihtml5/bootstrap3-wysihtml5.all.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/slimScroll/jquery.slimscroll.min.js"></script>')
          $("body").append('<script async="" type="text/javascript" crossorigin="anonymous" src="'+process.env.PUBLIC_URL+'/assets/plugins/fastclick/fastclick.js"></script>')
          $("body").append('<script src="'+process.env.PUBLIC_URL+'/assets/dist/js/app.min.js"></script>')
          $("body").append('<script src="'+process.env.PUBLIC_URL+'/assets/dist/js/demo.js"></script>')
          $("body").append('<script type="text/javascript" src="'+process.env.PUBLIC_URL+'newck/build/ckeditor.js"></script>')
          $("body").append('<script src="'+process.env.PUBLIC_URL+'/assets/plugins/select2/select2.full.min.js"></script>')
          $("body").append('<script  src="'+process.env.PUBLIC_URL+'/assets/assets/js/jquery.switcher.min.js"></script>')
          $("body").append('<script src="'+process.env.PUBLIC_URL+'/assets/plugins/datatables/jquery.dataTables.min.js"></script>')
          $("body").append('<script src="'+process.env.PUBLIC_URL+'/assets/plugins/datatables/dataTables.bootstrap.min.js"></script>')
          $("body").append('<script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"></script>')
          $("body").append('<script src="https://cdn.rawgit.com/bpampuch/pdfmake/0.1.27/build/pdfmake.min.js"></script>')
          $("body").append('<script src="https://cdn.rawgit.com/bpampuch/pdfmake/0.1.27/build/vfs_fonts.js"></script>')
          $("body").append('<script src="https://cdn.datatables.net/1.10.15/js/jquery.dataTables.min.js"></script>')
          $("body").append('<script src="https://cdn.datatables.net/buttons/1.4.0/js/dataTables.buttons.min.js"></script>')
          $("body").append('<script src="https://cdn.datatables.net/buttons/1.4.0/js/buttons.flash.min.js"></script>')
          $("body").append('<script src="https://cdn.datatables.net/buttons/1.4.0/js/buttons.html5.min.js"></script>')
          $("body").append('<script src="https://cdn.datatables.net/buttons/1.4.0/js/buttons.print.min.js"></script>')    
        }
        a++
    }  


    setTimeout(function(){
      setIsloading(false)
    },1000)
  },[])

  return (
    <>
    
    {
      isloadind &&
        <div style={{width:width,height:height,position:"fixed",top:0,left:0,zIndex:99999 ,backgroundColor:"#FFF"}}>
          <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
            <img src="http://localhost:3000/favicon.ico" />
          </div>
      </div>
    }

  <div className="wrapper">
    <header className="main-header">
      {/* Logo */}
      <a href="admin/dashboard" className="logo">
        {/* mini logo for sidebar mini 50x50 pixels */}
        <span className="logo-mini"><b>Admin</b></span>
        {/* logo for regular state and mobile devices */}
        <span className="logo-lg"><b>Admin</b></span>
      </a>
      {/* Header Navbar: style can be found in header.less */}
      <nav className="navbar navbar-static-top">
        {/* Sidebar toggle button*/}
        <a href="#" className="sidebar-toggle" data-toggle="offcanvas" role="button">
          <span className="sr-only">Toggle navigation</span>
        </a>

        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            {/* Notifications: style can be found in dropdown.less */}
            <li className="dropdown notifications-menu"  style={{display: 'none'}}>
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-bell-o"></i>
                <span className="label label-warning">500</span>
              </a>
            </li>
            
      
            {/* Tasks: style can be found in dropdown.less */}
            <li className="dropdown tasks-menu"  style={{display: 'none'}}>
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-users"></i>
                <span className="label label-danger">50</span>
              </a>
            </li>
            {/* User Account: style can be found in dropdown.less */}
            <li className="dropdown user user-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <img src="" className="user-image" alt="User Image" />
                <span className="hidden-xs">Application Name</span>
              </a>
              <ul className="dropdown-menu">
                {/* User image */}
                <li className="user-header">
                  <img src="#"className="img-circle" alt="User Image" />

                  <p>
                  Name
                    <small>Founder Of Application Name</small>
                  </p>
                </li>
                {/* Menu Body */}
                
                {/* Menu Footer*/}
                <li className="user-footer">
                  <div className="pull-left">
                      <a href="#" className="btn btn-default btn-flat">Profile</a>
                  </div>
                  <div className="pull-right">
                      <a href="#" className="btn btn-default btn-flat">Logout</a>
                  </div>
                </li>
              </ul>
            </li>
            {/* Control Sidebar Toggle Button */}
            <li style={{display: 'none'}}>
              <a href="#" data-toggle="control-sidebar"><i className="fa fa-gears"></i></a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    {/* Left side column. contains the logo and sidebar */}
    <aside className="main-sidebar">
      {/* sidebar: style can be found in sidebar.less */}
      <section className="sidebar">
        {/* Sidebar user panel */}
        <div className="user-panel">
          <div className="pull-left image">
            <img src="#" className="img-circle" alt="User Image" />
          </div>
          <div className="pull-left info">
            <p>Name</p>
            <a href="#"><i className="fa fa-circle text-success"></i> Online</a>
          </div>
        </div>
        
        {/* /.search form */}
        {/* sidebar menu: : style can be found in sidebar.less */}
        <ul className="sidebar-menu">
          <li className="header">MAIN NAVIGATION</li>
          <li className="treeview">
            <a href="#">
              <i className="fa fa-dashboard"></i> <span>Dashboard</span>
            </a>
          </li>
          <li className="treeview">
            <a href="#">
              <i className="fa fa-dashboard"></i> <span>Manage Domains</span>
            </a>
          </li>

          <li className="treeview">
          <a href="#">
            <i className="fa fa-rupee"></i> <span>Revenues</span>
            <span className="pull-right-container">
                <i className="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul className="treeview-menu">
              <li className="active">
                  <a href="#"><i className="fa fa-circle-o"></i> Manage Purchases
                      <span className="pull-right-container">
                          <small className="label pull-right bg-yellow">0</small>
                      </span>
                  </a>
              </li>
          </ul>
        </li>



        </ul>
      </section>
      {/* /.sidebar */}
    </aside>
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>
          Dashboard
          <small>Control panel</small>
        </h1>
        <ol className="breadcrumb">
          <li><a href="{{url('admin/dashboard')}}"><i className="fa fa-dashboard"></i> Home</a></li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
        <section className="content" style={{minHeight:height+"px"}}>
            <div className="row">
              <props.page />
            </div>
          </section>
      </div>
    </div>
      <Footer />
      </>
  )
}
