import React, { useEffect, useState } from 'react'
import $ from "jquery"
import apiurl from '../Global';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
const cookies = new Cookies();


const height = window.innerHeight;
const width = window.innerWidth;

export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    var a = 0
    useEffect(() => {
        if(a == 0){
            $('head').append('<link rel="stylesheet" href="'+ process.env.PUBLIC_URL +'/assets/assets/css/stylesheet.css">')
            a++;
            let getToken = cookies.get('token')
            if(typeof getToken !== "undefined" && getToken !== ""){
                navigate("/manage-websites")
            }else{
                setTimeout(function(){
                    $("#loading").fadeOut();
                },1000)
            }
        }
    }, [])

    const validateInput = (e) => {
        e.preventDefault();
        setLoading(true)
        fetch(apiurl+"api/vendor_login", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify({
                email: email,
                password: password,
            })
            })
            .then(response => response.json())
            .then(response => {
                
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    setEmail("")
                    setPassword("")
                    
                    let d = new Date();
                    d.setTime(d.getTime() + (60*60*1000));

                    cookies.set('token', response.token, { path: '/', expires:d });
                    cookies.set('vendor', JSON.stringify(response.vendor), { path: '/', expires:d });
                    
                    navigate("/manage-websites")

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    alert(response.message)
                }
                setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

  return (
    <>

        <div style={{width:width,height:height,position:"fixed",top:0,left:0,zIndex:99999 ,backgroundColor:"#FFF"}} id="loading">
            <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
                <img src={process.env.PUBLIC_URL + "assets/assets/images/loading.ico"} />
            </div>
        </div>
        
        <section className="basic-form">
            <div className="container basicflex">
                <div className="w70 float-left sideimage">
                    <div className="container backl">
                        <img src={process.env.PUBLIC_URL + "assets/assets/images/logscreen.jpg"} alt="" />
                    </div>
                    <div className="container frontl"></div>
                </div>
                <div className="w30 float-left formside">
                    {
                        loading &&
                        <div className='parcialLoading'>
                            <div className="loader"></div>
                        </div>
                    }
                    <div className="w90 float-center">
                        <div className="container website-logo">
                            <div className="logo-form">
                                <img src="https://www.website99.net/assets/images/website99logo.png" alt="" />
                            </div>
                        </div>
                        <div className="container log-heading center-text">
                            <p>Create an account</p>
                        </div>
                        <div className="container main-form">
                            <div className="w90 float-center">
                                <form method="POST" onSubmit={validateInput}>
                                    <div className="inputs container">
                                        <label>Email
                                            <input type="email" name="email"  placeholder="Enter your email" required="" onChange={e => setEmail(e.target.value)}/>
                                        </label>
                                    </div>
                                    <div className="inputs container">
                                        <label>Password
                                            <input type="password" name="password"  placeholder="Enter password" required="" onChange={e => setPassword(e.target.value)}/>
                                        </label>
                                    </div>
                                    <div className="inputs container">
                                        <button className="submitbutton">Create Account</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
