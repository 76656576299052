import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import apiurl from '../Global';
const cookies = new Cookies();



export default function Validateuser() {
    var a = 0;
    var user = 0
    const navigate = useNavigate()

    useEffect(()=>{
        if(a == 0){
            let getToken = cookies.get('token')
            let vendor = cookies.get('vendor')

            if(getToken == "undefined" || getToken == "" || getToken == null) {
                navigate("/Login")
            }
            else{
                fetch(apiurl+"api/validatevendor", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                        token: vendor.token,
                    })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(response.status == "error") {
                            navigate("/Login")
                        }
                        else{
                            if(typeof response.status !== "undefined" && response.status == "OK") {
                                var d = new Date();
                                d.setTime(d.getTime() + (60*60*1000));
                                cookies.set('vendor', JSON.stringify(response.vendor), { path: '/', expires:d })
                                console.log(1)
                            }
                            else if(typeof response.message !== "undefined" && response.status !== "OK") {
                                navigate("/Login")
                            }
                        }
                    })
                    .catch(err => {
                        // navigate("/Login")
                        console.log(err);
                    });
            }

            // var b = uservalidation(getToken, vendor)
            // user = b;
            // console.log(user+" Run")
        }
        a++;
    },[])

    // function uservalidation(getToken, vendor){
        
    // }
    
    // return {user}
}
