import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './admin/includes/Sidebar';
import Home from './Home';
import Signup from './admin/Signup';
import Login from './admin/Login';
import Admindashboard from './admin/Admindashboard';
import Managewebsites from './admin/Managewebsites';
import Addwebsite from './admin/Addwebsite';



function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/Signup" element={<Signup />} />
      <Route exact path="/Login" element={<Login />} />

      <Route exact path="/dashboard" element={<Admindashboard />} />
      <Route exact path="/manage-websites" element={<Managewebsites />} />
      <Route exact path="/add-website" element={<Addwebsite />} />

    </Routes>
  );
}

export default App;
