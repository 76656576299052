import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from './includes/Sidebar'

export default function Admindashboard() {
  const Dashboard = () => {
    return(
      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
                Div Test 
            </h3>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <Sidebar page={Dashboard}/>
    </>
  )
}
